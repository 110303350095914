import type { TagTheme } from '~/types/tagTheme'
import type { TranslationKeys } from '~/plugins/i18n'
import type { Filter } from '~/types/analytics'

export enum LegalReportType {
  BDESE = 'BDESE',
  EGAPRO_INDEX = 'EGAPRO_INDEX'
}

export enum LegalReportVersion {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export enum LegalReportTemplate {
  BDESE__BASIC_SMALL_COMPANY = 'BDESE__BASIC_SMALL_COMPANY',
  BDESE__BASIC_LARGE_COMPANY = 'BDESE__BASIC_LARGE_COMPANY',

  EGAPRO_INDEX__BASIC_SMALL_COMPANY = 'EGAPRO_INDEX__BASIC_SMALL_COMPANY',
  EGAPRO_INDEX__BASIC_LARGE_COMPANY = 'EGAPRO_INDEX__BASIC_LARGE_COMPANY',
  EGAPRO_INDEX__BASIC_HUGE_COMPANY = 'EGAPRO_INDEX__BASIC_HUGE_COMPANY'
}

export type TemplateConfig = {
  title: TranslationKeys
  description: TranslationKeys
  iconPath: string
  companySize: string
  tagTheme: TagTheme
}

export interface LegalReportBaseSection {
  content: string
  title: string
  description: string
}

export type LegalReportSection = LegalReportBaseSection & {
  id: string
  order: number
  isPublished: boolean
}

export type LegalReportParams = {
  year: number
  legalReportFilters: Filter[]
  realizedYearsNumber: number
  trendYearsNumber: number
  initialTemplate: LegalReportTemplate | null
}

export type PartialLegalReport = LegalReportParams & {
  id: string
  type: LegalReportType
  createdAt: Date
  publishedAt: Date
}

export type LegalReportAttachment = {
  id: string
  attachmentId: string
  filename: string
  uploadedAt: Date
  size: number
  isPublished: boolean
}

export type LegalReport = PartialLegalReport & {
  id: string
  createdAt: Date
  publishedAt: Date
  legalReportSections: LegalReportSection[]
  legalReportAttachments: LegalReportAttachment[]
}

export type LegalReportWithAccessType = LegalReport & {
  hasEditorAccess: boolean
}

export interface LegalReportConfig {
  name: string
  isCreationEnabled: boolean
  emoji: string
  title: TranslationKeys
  description: TranslationKeys
  typeTitle: TranslationKeys
}

export enum SectionTitleLevel {
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4'
}

export interface SectionTitle {
  title: string
  level: SectionTitleLevel
}

interface Section {
  id: string
  description: string
  titles: SectionTitle[]
}

export type TableOfContent = Section[]

export type LegalReportSectionConfig = {
  sectionTemplateId: string
  title: string
  description: string
  template: LegalReportTemplate
}

export type LegalReportTemplateSectionsConfig = {
  [key in LegalReportTemplate]: Omit<LegalReportSectionConfig, 'template'>[]
}
