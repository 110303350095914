import { BDESE_TEMPLATE_CONFIG } from './bdese'
import { EGAPRO_INDEX_TEMPLATE_CONFIG } from './egaproIndex'
import { DIMENSION_TITLES } from '~/constants/analytics'
import { FilterOperator } from '~/types/cube'
import {
  LegalReportType,
  type LegalReportConfig,
  LegalReportTemplate,
  type TemplateConfig
} from '~/types/legalReports/main'

export const LEGAL_REPORTS_CONFIG_BY_TYPE: {
  [key in LegalReportType]: LegalReportConfig
} = {
  [LegalReportType.BDESE]: {
    name: 'bdese',
    isCreationEnabled: true,
    emoji: '💽',
    title: 'legalReports.bdese.title',
    description: 'legalReports.bdese.description',
    typeTitle: 'legalReports.bdese.typeTitle'
  },
  [LegalReportType.EGAPRO_INDEX]: {
    name: 'egaproIndex',
    isCreationEnabled: true,
    emoji: '⚖️',
    title: 'legalReports.egaproIndex.title',
    description: 'legalReports.egaproIndex.description',
    typeTitle: 'legalReports.egaproIndex.typeTitle'
  }
}

export const LEGAL_REPORT_TEMPLATE_CONFIG: {
  [key in LegalReportTemplate]: TemplateConfig
} = {
  ...BDESE_TEMPLATE_CONFIG,
  ...EGAPRO_INDEX_TEMPLATE_CONFIG
}

export const LEGAL_REPORT_SCOPE_MAIN_DIMENSION_TITLE =
  DIMENSION_TITLES.EMPLOYEE_SUBORGANIZATION

export const ALLOWED_LEGAL_REPORT_SCOPE_FILTER_OPERATORS = [
  FilterOperator.EQUALS
]

export const LEGAL_REPORT_SCOPE_FILTER_MAX_COUNT = 2
