import {
  LegalReportTemplate,
  type TemplateConfig
} from '~/types/legalReports/main'
import { TagTheme } from '~/types/tagTheme'

export const BDESE_TEMPLATE_CONFIG = {
  [LegalReportTemplate.BDESE__BASIC_SMALL_COMPANY]: {
    title: 'legalReports.bdese.creation.template.basicSmallCompany.title',
    description:
      'legalReports.bdese.creation.template.basicSmallCompany.description',
    iconPath: new URL(
      '/assets/images/logo-french-admin.png',
      import.meta.url
    ).toString(),
    companySize: '👤 50-299',
    tagTheme: TagTheme.PRIMARY
  },
  [LegalReportTemplate.BDESE__BASIC_LARGE_COMPANY]: {
    title: 'legalReports.bdese.creation.template.basicLargeCompany.title',
    description:
      'legalReports.bdese.creation.template.basicLargeCompany.description',
    iconPath: new URL(
      '/assets/images/logo-french-admin.png',
      import.meta.url
    ).toString(),
    companySize: '👤 300+',
    tagTheme: TagTheme.QUINARY
  }
} as const satisfies { [key in LegalReportTemplate]?: TemplateConfig }

export const BDESE_REALIZED_YEAR_OPTIONS = [1, 2, 3]
export const BDESE_TREND_YEAR_OPTIONS = [0, 1, 2, 3]
