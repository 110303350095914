import { DIMENSION_TITLES } from '../analytics'
import type { TranslationKeys } from '~/plugins/i18n'
import {
  LegalReportTemplate,
  type TemplateConfig
} from '~/types/legalReports/main'
import { TagTheme } from '~/types/tagTheme'

export const EGAPRO_INDEX_TEMPLATE_CONFIG = {
  [LegalReportTemplate.EGAPRO_INDEX__BASIC_SMALL_COMPANY]: {
    title: 'legalReports.egaproIndex.creation.template.basicSmallCompany.title',
    description:
      'legalReports.egaproIndex.creation.template.basicSmallCompany.description',
    iconPath: new URL(
      '/assets/images/logo-french-admin.png',
      import.meta.url
    ).toString(),
    companySize: '👤 50-250',
    tagTheme: TagTheme.PRIMARY
  },
  [LegalReportTemplate.EGAPRO_INDEX__BASIC_LARGE_COMPANY]: {
    title: 'legalReports.egaproIndex.creation.template.basicLargeCompany.title',
    description:
      'legalReports.egaproIndex.creation.template.basicLargeCompany.description',
    iconPath: new URL(
      '/assets/images/logo-french-admin.png',
      import.meta.url
    ).toString(),
    companySize: '👤 251 - 999',
    tagTheme: TagTheme.QUINARY
  },
  [LegalReportTemplate.EGAPRO_INDEX__BASIC_HUGE_COMPANY]: {
    title: 'legalReports.egaproIndex.creation.template.basicHugeCompany.title',
    description:
      'legalReports.egaproIndex.creation.template.basicHugeCompany.description',
    iconPath: new URL(
      '/assets/images/logo-french-admin.png',
      import.meta.url
    ).toString(),
    companySize: '👤 1000+',
    tagTheme: TagTheme.QUINARY
  }
} as const satisfies {
  [key in LegalReportTemplate]?: TemplateConfig
}

export enum EgaproIndexSalaryRaisePeriodPreference {
  ONE = 'ONE',
  TWO = 'TWO',
  THREE = 'THREE'
}

export enum EgaproIndexCategorizationDimension {
  PROFESSIONAL_CATEGORY = 'PROFESSIONAL_CATEGORY',
  DEPARTMENT = 'DEPARTMENT',
  CAREER_LEVEL = 'CAREER_LEVEL',
  GROUP_1 = 'GROUP_1'
}

export const DEFAULT_SALARY_RAISE_PERIOD_PREFERENCE =
  EgaproIndexSalaryRaisePeriodPreference.ONE

export const DEFAULT_CATEGORIZATION_DIMENSION =
  EgaproIndexCategorizationDimension.PROFESSIONAL_CATEGORY

export const EGAPRO_INDEX_REALIZED_YEARS_NUMBER = 1

export const EGAPRO_INDEX_TREND_YEARS_NUMBER = 0

export const SALARY_RAISE_PERIOD_PREFERENCE_OPTIONS: {
  [key in EgaproIndexSalaryRaisePeriodPreference]: {
    key: TranslationKeys
    value: number
  }
} = {
  [EgaproIndexSalaryRaisePeriodPreference.ONE]: {
    key: 'legalReports.egaproIndex.creation.templateStep.salaryRaisePeriodPreference.options.ONE',
    value: 1
  },
  [EgaproIndexSalaryRaisePeriodPreference.TWO]: {
    key: 'legalReports.egaproIndex.creation.templateStep.salaryRaisePeriodPreference.options.TWO',
    value: 2
  },
  [EgaproIndexSalaryRaisePeriodPreference.THREE]: {
    key: 'legalReports.egaproIndex.creation.templateStep.salaryRaisePeriodPreference.options.THREE',
    value: 3
  }
}

export const DIMENSION_TITLE_BY_CATEGORIZATION_DIMENSION: {
  [key in EgaproIndexCategorizationDimension]: DIMENSION_TITLES
} = {
  [EgaproIndexCategorizationDimension.PROFESSIONAL_CATEGORY]:
    DIMENSION_TITLES.EMPLOYEE_PROFESSIONAL_CATEGORY,
  [EgaproIndexCategorizationDimension.DEPARTMENT]:
    DIMENSION_TITLES.EMPLOYEE_DEPARTMENT,
  [EgaproIndexCategorizationDimension.CAREER_LEVEL]:
    DIMENSION_TITLES.EMPLOYEE_CAREER_LEVEL,
  [EgaproIndexCategorizationDimension.GROUP_1]:
    DIMENSION_TITLES.EMPLOYEE_GROUP_1
}
